import useApi from '../../utils/hooks/useApi';
import { getCurrentDate } from '../../utils/dateFormatter';
import useQueryParam from '../../utils/hooks/useQueryParam';
import NumberSpinner from '../../uikit/numberSpinner';

const TotalCount = () => {
    const [dateParam] = useQueryParam('date');
    const [clubName] = useQueryParam('club_name');
    const currentDate = dateParam || getCurrentDate();

    const { data: totalMembers, isLoading } = useApi({
        queryKey: ['totalMembers', currentDate, clubName],
        url: `/total-member-count?date=${currentDate}`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    const { data: movementInMembers } = useApi({
        queryKey: ['movementInMembers', currentDate, clubName],
        url: `/movement-in-members?date=${currentDate}`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    return (<div className='flex flex-col gap-4 w-full'>
        <div className="w-full h-full">
            <div className="w-full h-full">
                <div className="container w-full h-full max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
                    <div className="header flex justify-between pt-2.5 mb-2.5 px-2 truncate">
                        <div className="text-gray-900 dark:text-moon-50 font-semibold text-base leading-6">💪 Total Members</div>
                    </div>
                    <div
                        data-view-component="true"
                        className="px-2 font-bold text-gray-900 dark:text-moon-50 text-3xl md:text-4xl leading-10"
                    >
                        <span
                        >
                            <NumberSpinner digitsCount={4} isLoading={isLoading} actualNumber={isLoading ? null : totalMembers?.total_member_count} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="w-full h-full">
            <div className="w-full h-full">
                <div className="container w-full h-full max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
                    <div className="header flex justify-between pt-2.5 mb-2.5 px-2 truncate">
                        <div className="text-gray-900 dark:text-moon-50 font-semibold text-base leading-6">🚵‍♂️ Movement In Members</div>
                    </div>
                    <div
                        data-view-component="true"
                        className="px-2 font-bold text-gray-900 dark:text-moon-50 text-3xl md:text-4xl leading-10"
                    >
                        <span
                        >
                            <NumberSpinner digitsCount={2} actualNumber={movementInMembers?.movement_in_members} />
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </div>)
}

export default TotalCount