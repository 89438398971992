import DayTable from "./DayTable";
import Highlights from "./Highlights";
import Stats from "./Stats";

const Home = () => {
  return (
    <div className="px-3">
      <Stats />
      <div className="flex flex-col md:flex-row"><div className="w-full md:w-9/12"><DayTable /></div><div className="w-full md:w-1/4"><Highlights /> </div> </div>
    </div>
  );
};

export default Home;
