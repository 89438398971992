import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumberWithThousandsSeparator } from '../../utils/commonUtils';

const NumberSpinner = ({ digitsCount, actualNumber }) => {
  const [displayedNumber, setDisplayedNumber] = useState('');
  const [spinning, setSpinning] = useState(true);

  // Split digitsCount into integer and decimal parts
  const [integerDigits, decimalDigits] = String(digitsCount).split('.').map(Number);

  // Helper function to format the number with leading zeros and correct decimal places
  const formatNumber = (number) => {
    const [integerPart, decimalPart] = String(number).split('.');

    // Pad the integer part with leading zeros
    const paddedInteger = integerPart.padStart(integerDigits, '0');

    // Format the decimal part if decimalDigits exist
    const formattedDecimal = decimalDigits
      ? (decimalPart || '').padEnd(decimalDigits, '0').slice(0, decimalDigits)
      : '';

    return decimalDigits ? `${paddedInteger}.${formattedDecimal}` : paddedInteger;
  };

  useEffect(() => {
    let interval;

    if (actualNumber == null) {
      // Start spinning with random digits if the actual number is not available
      interval = setInterval(() => {
        const randomInteger = Array(integerDigits)
          .fill(0)
          .map(() => Math.floor(Math.random() * 10))
          .join('');

        const randomDecimal = decimalDigits
          ? Array(decimalDigits)
            .fill(0)
            .map(() => Math.floor(Math.random() * 10))
            .join('')
          : '';

        setDisplayedNumber(decimalDigits ? `${randomInteger}.${randomDecimal}` : randomInteger);
      }, 100);
    }

    if (actualNumber != null) {
      // Once the actual number is available, stop spinning and format the number
      clearInterval(interval);
      setSpinning(false);
      const formattedNumber = formatNumber(actualNumber);
      setDisplayedNumber(formatNumberWithThousandsSeparator(formattedNumber));
    }

    return () => {
      clearInterval(interval);
    };
  }, [spinning, integerDigits, decimalDigits, actualNumber]);

  return (
    <div className="inline-block">
      {displayedNumber.split('').map((char, index) => (
        <span
          key={index}
          className={`inline-block dark:text-moon-50 transition-transform duration-300 ${spinning && char !== '.' ? 'animate-pulse' : 'transform-none'
            }`}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

// PropTypes definition
NumberSpinner.propTypes = {
  digitsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // Can be "6" or "6.2"
  actualNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Actual number to display, can include decimals
};

export default NumberSpinner;