import HighlightsCard from "./HighlightsCard"
const Highlights = () => {
    return (
        <div className="h-[815px] md:pl-4 mt-4 md:mt-auto w-full scrollbar space-y-4">
            <div className="h-[815px]">
                <div className="container scrollbar overflow-y-auto h-[815px] max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
                    <div className="header border-b border-gray-200 dark:border-moon-700 flex justify-between pt-2.5 pb-2.5 px-2 truncate">
                        <div className="text-gray-900 dark:text-moon-50 font-semibold text-base leading-6">🗒️ Highlights</div>
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="Record-breaking Member Engagement!"
                            category="#Engagement"
                            date="September 22, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="New Fitness Milestones Achieved!"
                            category="#Milestones"
                            date="October 01, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="New Equipment Added to Gym Levels!"
                            category="#GymUpgrades"
                            date="September 28, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="Top-performing Gym ALX!"
                            category="#TopGym"
                            date="September 27, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="Member of the Month Spotlight!"
                            category="#MemOfTheMonth"
                            date="September 20, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="10,000 Total Workouts Completed!"
                            category="#Achievements"
                            date="September 25, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="Gym Surpasses 500 Hours of Training!"
                            category="#TrainingHours"
                            date="October 03, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="500 Members Achieved Personal Bests!"
                            category="#PersonalBests"
                            date="October 02, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="New Gym Record: 1,000,000 Calories Burned!"
                            category="#CaloriesBurned"
                            date="September 30, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="Highest Ever Gym Attendance Recorded!"
                            category="#GymAttendance"
                            date="October 01, 2024"
                        />
                    </div>
                    <div className="py-2">
                        <HighlightsCard
                            title="New Equipment Added to Gym Levels!"
                            category="#GymUpgrades"
                            date="September 27, 2024"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Highlights