import MemberGainers from './MemberGainers'
import MemberLosers from './MemberLosers'
import TotalCount from './TotalCount'

const Stats = () => {
    return (
        <div className='flex flex-col md:flex-row gap-5 my-4 2lg:my-9'><TotalCount /> <MemberGainers /> <MemberLosers /></div>
    )
}

export default Stats