import { useQuery } from "@tanstack/react-query";
import { getCurrentDate } from "../../utils/dateFormatter";
import SimpleClientTable from "../../uikit/simple-client-table";
import { useMemo, useState } from "react";
import useQueryParam from "../../utils/hooks/useQueryParam";
import ButtonGroup from "../details/ButtonGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { formatNumberWithThousandsSeparator } from "../../utils/commonUtils";

const MemberLosers = () => {
    const [dateParam] = useQueryParam('date');
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = useQuery({
        queryKey: ['allMemberGainerLooserData', dateParam || getCurrentDate()],
        initialData: () => [],
    });

    const buttons = [
        { id: 'movement_in_members', label: 'Members', key: 'movement_in_members' },
        { id: 'movement_in_points', label: 'Points', key: 'movement_in_points' },
    ];

    const [isPointView, setIsPointView] = useState(false)

    const columns = useMemo(
        () => [
            {
                accessorKey: 'club_name',
                header: 'Club Name',
                id: 'name',
                cell: (row) => (
                    <>
                        <div className="flex items-center gap-1">
                            <div>
                                <img src={row?.row?.original?.image_url} className='w-6 h-6 ' width={24} height={24} alt={row?.row?.original?.['club_name']} />
                            </div>
                            <div className='text-xs leading-4 text-gray-500 dark:text-moon-200'>
                                {row?.row?.original?.['club_name']}
                            </div>
                            <div className="text-black">

                            </div>
                        </div>
                    </>
                ),
            },
            {
                accessorKey: isPointView ? 'movement_in_points' : `movement_in_members`,
                header: `Movement`,
                id: isPointView ? 'movementInMembers' : 'movementInPoints',
                cell: (row) => (
                    <div className="flex items-center gap-1 justify-end">
                        <div>
                            {isPointView ? formatNumberWithThousandsSeparator(row?.row?.original?.movement_in_points) : formatNumberWithThousandsSeparator(row?.row?.original?.movement_in_members)} <span className="text-gray-500 text-xs">{isPointView ? 'Points' : 'Members'}</span>
                        </div>
                        <div className="leading-4 text-gray-500">
                            /
                        </div>
                        <div>
                            {isPointView ? formatNumberWithThousandsSeparator(row?.row?.original?.movement_percentage_points) : formatNumberWithThousandsSeparator(row?.row?.original?.movement_percentage_members)} <span className="text-gray-500">%</span>
                        </div>
                    </div>

                ),
            },
        ],
        [isPointView]
    );

    const handleMetricChange = (value) => {
        if (value === 'movement_in_members') {
            setIsPointView(false)
        } else {
            setIsPointView(true)
        }
    };

    const handleRowClick = (row) => {
        navigate(`/${row?.original?.club_name}${location.search}`);
    }

    const tableData = isPointView ? data?.topPointLosers : data?.topMemberLosers


    return (
        <div className="w-full">
            <div className="container max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
                <div className="header w-full flex justify-between pt-2.5 mb-2.5 px-2 truncate">
                    <div className="w-full text-gray-900 dark:text-moon-50 font-semibold text-base leading-6 flex justify-between items-center">
                        <div>🚨 Top Losers</div>
                        <div>
                            <ButtonGroup buttons={buttons} defaultActive="movement_in_members" onClick={handleMetricChange} />
                        </div>
                    </div>
                </div>
                <SimpleClientTable columns={columns} data={tableData || []} handleRowClick={handleRowClick} />
            </div>
        </div>
    )
}

export default MemberLosers