import { useQuery } from "@tanstack/react-query";
import { getCurrentDate } from "../../utils/dateFormatter";
import SimpleClientTable from "../../uikit/simple-client-table";
import { useMemo } from "react";
import { FAKE_MERGED_DATA } from "../../utils/dummyAPIResponse";
import ArrowIcon from "../../common/ImageJSX/ArrowIcon";
import { formatNumberWithThousandsSeparator, getColorCode, roundToTwoDecimals } from "../../utils/commonUtils";
import useQueryParam from "../../utils/hooks/useQueryParam";
import TrendLine from "./TrendLine";
import { BASE_URL } from "../../apiConstant";
import { useLocation, useNavigate } from "react-router-dom";

const DayTable = () => {
    const [dateParam] = useQueryParam('date');
    const navigate = useNavigate();
    const location = useLocation();
    const currentDate = dateParam || getCurrentDate()
    const { data: mergedData = [] } = useQuery({
        queryKey: ['allMemberMergedData', currentDate],
        initialData: () => FAKE_MERGED_DATA,
    });

    const fetchTrendData = async (clubName) => {
        const response = await fetch(
            `${BASE_URL}/public-members?period=daily&order_by_date=desc&club_name=${clubName}&startDate=2024-07-31&endDate=${currentDate}&page_size=10&page=1`
        );
        return response.json();
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'club_name',
                header: 'Club Names',
                id: 'name',
                sticky: true,
                cell: (row) => (
                    <>
                        <div className="flex items-center gap-1">
                            <div>
                                <ArrowIcon direction={row?.row?.original?.['movement_in_members'] < 0 ? 'down' : 'up'} color={getColorCode(row?.row?.original?.['movement_in_members'])} />
                            </div>
                            <div>
                                <img src={row?.row?.original?.image_url} className='w-6 h-6 ' width={24} height={24} alt={row?.row?.original?.['club_name']} />
                            </div>
                            <div className='text-xs leading-4 text-gray-500 dark:text-moon-200'>
                                {row?.row?.original?.['club_name']}
                            </div>
                            <div className="text-black">

                            </div>
                        </div>
                    </>
                ),
            },
            {
                accessorKey: 'member_count',
                header: 'Members Count',
                alignment: 'center',
                id: 'memberCount',
                cell: ({ cell }) => formatNumberWithThousandsSeparator(cell.getValue()),
            },
            {
                accessorKey: 'movement_in_members',
                header: 'Movement In Members',
                alignment: 'center',
                id: 'movementInMembers',
                cell: ({ cell }) => formatNumberWithThousandsSeparator(cell.getValue()),

            },
            {
                accessorKey: 'total_points',
                header: 'Total Points',
                id: 'totalPoints',
                alignment: 'center',
                cell: ({ cell }) => formatNumberWithThousandsSeparator(roundToTwoDecimals(cell.getValue())),
            },
            {
                accessorKey: 'movement_in_points',
                header: 'Movement in Points',
                id: 'movementInPoints',
                alignment: 'center',
                cell: ({ cell }) => formatNumberWithThousandsSeparator(roundToTwoDecimals(cell.getValue())),
            },

            {
                accessorKey: 'movement_percentage_points',
                header: 'Last 10 Days',
                id: 'movementInMembersTrend',
                width: 100,
                maxWidth: 100,
                sortable: false,
                cell: ({ row }) => {
                    const { club_name, name } = row.original; // Extract parameters from the row

                    // Use useQuery to fetch trend data for the current row
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const { data: trendData, isLoading: trendLoading } = useQuery({
                        queryKey: ['trendData', club_name, name, currentDate], // Only fetch for the current row
                        queryFn: () => fetchTrendData(club_name, name), // Fetch data for the trend line
                        enabled: !!club_name && !!name, // Only run if parameters are available
                        refetchOnWindowFocus: false,
                        staleTime: Infinity,
                    });

                    if (trendLoading) {
                        return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>;
                    }

                    if (!trendData) {
                        return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data</div>;
                    }

                    const formattedTrendData = trendData.data
                        .map((item) => ({
                            time: item.name, // Use name as time
                            value: item.movement_percentage_points, // Use movement_percentage_points as value
                        }))
                        .sort((a, b) => new Date(a.time) - new Date(b.time)); // Sort by time in ascending order

                    // Render the trend line with the formatted trend data
                    return <div style={{ width: '100%', minWidth: '100px', padding: '0 0px' }}>
                        <TrendLine data={formattedTrendData} />
                    </div>;
                },
            },
        ],
        [currentDate]
    );

    const handleRowClick = (row) => {
        navigate(`/${row?.original?.club_name}${location.search}`);
    }

    return (
        <div className="ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
            <SimpleClientTable isPaginationRequired={false} columns={columns} pageSize={20} data={mergedData?.data || []} handleRowClick={handleRowClick} />
        </div>
    )
}

export default DayTable