import React from 'react'
import { formatNumberWithThousandsSeparator, getColorCode, roundToTwoDecimals } from '../../utils/commonUtils'
import ArrowIcon from '../../common/ImageJSX/ArrowIcon'
import NumberSpinner from '../../uikit/numberSpinner'
import InfoTable from '../../uikit/infoTable'
import useQueryParam from '../../utils/hooks/useQueryParam'
import { getCurrentDate } from '../../utils/dateFormatter'
import useApi from '../../utils/hooks/useApi'
import RankingTable from './RankingTable'
import MemberChart from './MemberChart'

const ClubDetail = ({ data, clubRanking, sortedPoints, clubName, startDate, endDate }) => {
    const [dateParam] = useQueryParam('date');
    const currentDate = dateParam || getCurrentDate();

    const { data: clubTierLevelInfo, error, isLoading, refetch } = useApi({
        queryKey: ['clubTierLevelInfo', currentDate, data?.club_name],
        // url: `/membership-tiers?period=daily&club_name=ALX&startDate=2024-10-02&endDate=2024-10-02&page=1&page_size=10&paginate=true`,
        url: `/membership-tiers?startDate=${currentDate}&endDate=${currentDate}&club_name=${data?.club_name}&period=daily&paginate=true&page=1&page_size=10`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    return (<>
        <div className="flex flex-col">
            <div className="flex-1" >
                <div className="mb-2 lg:mb-3 flex items-center ">
                    <img
                        className="rounded-full h-8 w-8"
                        alt={`${data?.club_name} logo`}
                        src={data?.image_url}
                    />
                    <h1 className="flex items-center gap-x-1 flex-wrap">
                        <div
                            data-view-component="true"
                            className="text-base 2lg:text-lg font-bold text-gray-900 dark:text-moon-50 leading-7"
                        >
                            {data?.club_name}
                        </div>
                        <span
                            data-view-component="true"
                            className="mt-0.5 font-normal text-gray-500 dark:text-moon-200 text-sm leading-5"
                        >
                            Club
                        </span>
                    </h1>
                    <span
                        data-view-component="true"
                        className="mt-0.5 ml-1 mr-3 2lg:mr-0 inline-flex items-center px-1.5 py-0.5 bg-gray-100 dark:bg-moon-400/20 rounded-md"
                    >
                        <div
                            data-view-component="true"
                            className="text-xs leading-4 text-gray-700 dark:text-moon-200 font-medium"
                        >
                            #{clubRanking}
                        </div>
                    </span>
                </div>
                <div className="mb-2 lg:mb-3 flex items-center">
                    <div
                        data-view-component="true"
                        className="font-bold text-gray-900 dark:text-moon-50 text-3xl md:text-4xl leading-10"
                    >
                        <span
                        >
                            <NumberSpinner digitsCount={4} actualNumber={data?.member_count} />
                        </span>
                    </div>
                    <div
                        data-view-component="true"
                        className="font-bold text-gray-900 dark:text-moon-50 text-lg md:text-xl leading-7"
                    >
                        <span
                            className="flex justify-center items-center"
                        >
                            <ArrowIcon direction={data['movement_in_members'] < 0 ? 'down' : 'up'} color={getColorCode(data['movement_in_members'])} />
                            <NumberSpinner digitsCount={1.2} actualNumber={data?.movement_percentage_points} />%
                        </span>
                    </div>
                </div>
                <div
                    data-view-component="true"
                    className="mb-3 text-xs leading-4 text-gray-500 dark:text-moon-200 font-regular"
                >
                    <div>
                        Membership Tier : ALL
                    </div>
                </div>
            </div>

        </div>
        <div>
            <InfoTable rows={[
                {
                    label: 'Total Points',
                    value: formatNumberWithThousandsSeparator(roundToTwoDecimals(data?.total_points)),
                },

                {
                    label: 'Movement In Members',
                    value: data?.movement_in_members ? `${formatNumberWithThousandsSeparator(data?.movement_in_members)} / ${formatNumberWithThousandsSeparator(data?.movement_percentage_members)}%` : '--',
                },
                {
                    label: 'Movement in Points',
                    value: data?.movement_in_points ? `${formatNumberWithThousandsSeparator(data?.movement_in_points)} / ${formatNumberWithThousandsSeparator(data?.movement_percentage_points)}%` : '--',
                },
            ]} />
        </div>
        <div className='mt-4 md:hidden'>
            <MemberChart period="daily"
                clubName={clubName}
                startDate={startDate}
                endDate={endDate} />
        </div>
        <div className='mt-4'>
            <div className="font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">
                Membership Tier Wise
            </div>
            <InfoTable rows={(clubTierLevelInfo?.data ?? []).map((item) => ({
                label: `${item?.membership_tier}`,
                value: <span
                    className="flex justify-center items-center"
                >
                    <ArrowIcon direction={item.movement_percentage_points < 0 ? 'down' : 'up'} color={getColorCode(item.movement_percentage_points)} />
                    <NumberSpinner digitsCount={1.2} actualNumber={item.movement_percentage_points} />%
                </span>,
                expandable: true,
                expandableContent: [
                    {
                        label: 'Members Count',
                        value: formatNumberWithThousandsSeparator(item?.total_members),
                    },
                    {
                        label: 'Movement In Members',
                        value: item?.movement_in_members ?
                            `${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_in_members))} / ${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_percentage_members))}%` : '--',
                    },
                    {
                        label: 'Total Points',
                        value: formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.total_points)),
                    },
                    {
                        label: 'Movement in Points',
                        value: item?.movement_in_points ?
                            `${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_in_points))} / ${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_percentage_points))}%` : '--',
                    },
                ]
            }))} />
        </div>
        <div className='mt-4'>
            <div className="font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">
                Ranking <span className='text-xs text-gray-400'>(Based On Points)</span>
            </div>
            <RankingTable data={sortedPoints} />
        </div>
    </>)
}

export default ClubDetail