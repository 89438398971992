export const FAKE_MERGED_DATA = { data: [
    {
        "club_name": "ALX",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/KjLVmmxw/Alexandria-Avengers.png"
    },
    {
        "club_name": "BNK",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/Z5j9KpdN/Bankstown-Bolts.png"
    },
    {
        "club_name": "CAR",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/L87pTsd7/Caringbah-Commandos.png"
    },
    {
        "club_name": "CHD",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/VvxHNwgV/Chadstone-Chiefs.png"
    },
    {
        "club_name": "CHT",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
       "image_url": "https://i.postimg.cc/1RBG5sCn/Chatswood-Crusaders.png"
    },
    {
        "club_name": "DEE",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/MZ3nFT3D/Deewhy-Defenders.png"
    },
    {
        "club_name": "EPP",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/tTM921Rn/Epping-Eagles.png"
    },
    {
        "club_name": "HOP",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/3wyhW8FK/Hoppers-Hawks.png"
    },
    {
        "club_name": "HOX",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/J0pYbJRS/Hoxton-Hurricanes.png"
    },
    {
        "club_name": "HPT",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/L4PsNTfH/Highpoint-Heros.png"
    },
    {
        "club_name": "MEN",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/3rjk31wt/Mentone-Marvels.png"
    },
    {
        "club_name": "MOR",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/CLXTXTVX/Mortdale-Magic.png"
    },
    {
        "club_name": "NUN",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/1tfbJJjs/Nunawading-Ninjas.png"
    },
    {
        "club_name": "PAR",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/7YMt1FZS/Parramatta-Patriots.png"
    },
    {
        "club_name": "RCH",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/PfM1ST1f/richmondrangers.png"
    },
    {
        "club_name": "RCK",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/D0mfQrqX/Rockdale-Rebels.png"
    },
    {
        "club_name": "SPR",
        "membership_tier": [
            "Base Dues",
            "Base PIF",
            "Summit Dues",
            "Summit PIF"
        ],
        "image_url": "https://i.postimg.cc/fbBhH3Wj/Springvale-Samurais.png"
    }
]}