import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useGlobal } from '../../utils/contexts/GlobalContext';

const TrendLine = ({ data }) => {
    const { state } = useGlobal(); // Get global state and dispatch
    const isDarkMode = state.isDarkMode; // Access dark mode state from global context
    const chartContainerRef = useRef(null);
    const chartInstanceRef = useRef(null); // Ref to store chart instance


    useEffect(() => {
        const handleResize = () => {
            const { current: chartContainer } = chartContainerRef;
            if (chartContainer && chartInstanceRef.current) {
                const rect = chartContainer.getBoundingClientRect();
                chartInstanceRef.current.applyOptions({
                    width: Math.floor(rect.width),
                    height: Math.floor(rect.height)
                });
                chartInstanceRef.current.timeScale().fitContent(); // Fit content to visible range
            }
        };

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: {
                    color: isDarkMode ? '#0d1217' : 'white',
                },
                textColor: isDarkMode ? 'white' : 'black', // Optional: Change text color based on dark mode
            },
            grid: {
                vertLines: { visible: false },
                horzLines: { visible: false },
            },
            crosshair: {
                vertLine: { visible: false },
                horzLine: { visible: false },
            },
            timeScale: {
                visible: false,
                borderVisible: false,
                fitContent: true, // Ensure it fits the content
            },
            rightPriceScale: {
                visible: false,
                borderVisible: false,
            },
            handleScroll: false,
            handleScale: false,
        });

        chartInstanceRef.current = chart; // Store chart instance

        const baselineSeries = chart.addBaselineSeries({
            baseValue: { type: 'price', price: 0 },
            topLineColor: 'rgba(38, 166, 154, 1)',
            bottomLineColor: 'rgba(239, 83, 80, 1)',
            lineWidth: 2,
            crosshairMarkerVisible: false,
            lastValueVisible: false,
            priceLineVisible: false,
        });

        baselineSeries.setData(data);

        const removeLogo = () => {
            const logos = chartContainerRef.current.querySelectorAll('[title="Charting by TradingView"]');
            logos.forEach(logo => {
                logo.style.display = 'none';
            });
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(chartContainerRef.current);

        // Initial resize and logo removal
        handleResize();
        removeLogo();

        return () => {
            resizeObserver.disconnect();
            chart.remove();
        }
    }, [data, isDarkMode]);

    const containerStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };

    return (
        <div className='px-5' style={{ width: '100%', height: '20px', position: 'relative', overflow: 'hidden' }}>
            <div ref={chartContainerRef} style={containerStyle} />
        </div>
    );
};

export default TrendLine;